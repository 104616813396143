<template>
  <CRow class="mb-4 mt-4">
    <CCol col="12">
      <div class="form-group">
        <h5 for="acceptance-terms">
          <b>Termos de Aceite</b>
        </h5>

        <label>
          Preencha o campo abaixo com o texto que será exibido em todos os
          formulários para aceite do usuário, lembre-se de colocar o link para a
          política de privacidade.
        </label>
        <TextEditor id="acceptance-terms" v-model="text" type="simpletext" />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import TextEditor from '@/components/ui/TextEditor'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { TextEditor },
  methods: { ...mapActions('settings', ['input']) },
  computed: {
    ...mapGetters('settings', ['form']),
    text: {
      get() {
        return this.form.acceptance_text
      },
      set(acceptance_text) {
        this.input({ acceptance_text })
      }
    }
  }
}
</script>
