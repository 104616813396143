<template>
  <CRow class="my-4 justify-content-between">
    <CCol col="12" lg="8" xl="8">
      <!-- Enable alert  -->
      <CRow class="mb-5">
        <CCol col="12">
          <h5>
            <b>Cookies</b>
          </h5>
        </CCol>

        <CCol col="12" sm="6">
          <div class="d-flex align-items-center">
            <label for="cookies_notification" class="mb-0 mr-1">
              Ativar alerta de uso de cookies
            </label>
            <CSwitch
              id="cookies_notification"
              class="mx-1"
              color="success"
              variant="3d"
              v-bind="labelIcon"
              :checked="form.cookies_notification === '1' ? true : false"
              @update:checked="setCookiesNotification($event)"
            />
          </div>
        </CCol>
      </CRow>

      <!-- Layout -->
      <CRow
        v-if="form.cookies_notification !== '0' && form.cookies_notification"
        class="mb-5"
      >
        <CCol col="12">
          <h5>
            <b>Posição</b>
          </h5>

          <p>
            Determine a localização no site que você gostaria de exibir o aviso:
          </p>

          <div class="preview-select">
            <div
              v-for="(item, index) in positions"
              :key="item[index]"
              v-c-tooltip="{
                content: item.label,
                placement: 'top',
                appendToBody: true
              }"
              :class="{
                active: item.position == form.cookies_notification_position
              }"
              @click="selectCookiePosition(item.position)"
            >
              <img
                :src="require(`@/assets/img/positions/${item.position}.png`)"
                :alt="`Posição ${item.label}`"
              />
            </div>
          </div>
        </CCol>
      </CRow>

      <!-- Controller -->
      <CRow
        v-if="form.cookies_notification !== '0' && form.cookies_notification"
        class="mb-5"
      >
        <CCol col="12">
          <h5>
            <b>Controlador</b>
          </h5>

          <p>
            Determine a localização no site que você gostaria de exibir o
            controlador após os cookies serem aceitos:
          </p>

          <div class="preview-select">
            <div
              v-for="(item, index) in controllers"
              :key="item[index]"
              v-c-tooltip="{
                content: item.label,
                placement: 'top',
                appendToBody: true
              }"
              :class="{
                active: item.position === form.cookies_notification_controller
              }"
              @click="selectCookieController(item.position)"
            >
              <img
                :src="require(`@/assets/img/controllers/${item.position}.png`)"
                :alt="`Controlador ${item.label}`"
              />
            </div>
          </div>
        </CCol>
      </CRow>

      <!-- Type -->
      <CRow
        v-if="form.cookies_notification !== '0' && form.cookies_notification"
        class="mt-5"
      >
        <CCol col="12">
          <h5 class="mb-4">
            <b>Tipo de aviso</b>
          </h5>

          <div class="custom-control custom-radio mb-2">
            <input
              id="customRadioInline1"
              v-model="form.cookies_notification_version"
              @input="
                (e) => input({ cookies_notification_version: e.target.value })
              "
              value="simple"
              type="radio"
              name="customRadioInline1"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="customRadioInline1">
              Versão simples
            </label>
            <small style="margin-left: 10px" class="span_description">
              (Exibir apenas a notificação de utilização dos cookies, mas
              <b>NÃO</b> permite o controle dos cookies utilizados.)
            </small>
          </div>
          <div class="custom-control custom-radio">
            <input
              id="customRadioInline2"
              v-model="form.cookies_notification_version"
              @input="
                (e) => input({ cookies_notification_version: e.target.value })
              "
              value="complete"
              type="radio"
              name="customRadioInline1"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="customRadioInline2">
              Versão completa
            </label>
            <small style="margin-left: 10px" class="span_description">
              (Exibir a notificação de utilização dos cookies e permite a
              personalização do controle dos cookies utilizados.)
            </small>
          </div>

          <div
            v-if="form.cookies_notification_version === 'complete'"
            class="form-group mt-4"
          >
            <CAlert color="warning" class="ml-1 mt-2">
              ⚠️&nbsp;Para exibir essas informações no site é necessário
              cadastar os cookies corretamente na
              <a href="/cookies" target="_self">página</a>.
            </CAlert>
          </div>
        </CCol>
      </CRow>
    </CCol>

    <CCol
      v-if="form.cookies_notification !== '0' && form.cookies_notification"
      col="12"
      lg="4"
      xl="4"
      class="d-none d-lg-block"
    >
      <div>
        <h5>
          <b>Preview</b>
        </h5>

        <div class="preview-image">
          <img
            :src="require(`@/assets/img/${selectCookieImage}.svg`)"
            alt="Preview cookie position"
          />
        </div>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import labelIconMixin from '@/mixin/label-icon'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [labelIconMixin],
  data: () => ({
    positions: [
      { position: 'left', label: 'Esquerda' },
      // { position: 'right', label: 'Direita' },
      // { position: 'center', label: 'Centro' },
      { position: 'top', label: 'Topo' },
      { position: 'bottom', label: 'Embaixo' }
    ],
    controllers: [
      { position: 'none', label: 'Sem controlador' },
      { position: 'left', label: 'Esquerda' }
      // { position: 'right', label: 'Direita' }
    ]
  }),
  computed: {
    ...mapGetters('settings', {
      globalForm: 'form',
      selectCookieImage: 'selectCookieImage'
    }),
    form() {
      return { ...this.globalForm }
    }
  },
  methods: {
    ...mapActions('settings', [
      'input',
      'selectCookiePosition',
      'selectCookieController'
    ]),
    setCookiesNotification(cookies_notification) {
      this.input({ cookies_notification })
    }
  },
  watch: {
    'form.cookies_notification_position'(val, oldVal) {
      if (val === oldVal || !val) return
      this.selectCookiePosition(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.preview-select {
  display: flex;

  div {
    margin: 0px 10px;
    cursor: pointer;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    img {
      border: 2px solid $secondary-600;
    }

    &.active {
      img {
        border-color: $success;
      }
    }
  }
}

.preview-image {
  transform: translateX(-4%);

  img {
    width: 100%;
  }
}

.span_description {
  color: #768192;
  margin-top: 0.25rem;
}
</style>
