import vue from 'vue'

export default vue.extend({
  data: () => ({
    labelIcon: {
      labelOn: '\u2713',
      labelOff: '\u2715'
    }
  })
})
