<template>
  <CRow class="mb-4 mt-4">
    <CCol col="12">
      <div class="form-group">
        <h5 for="privacy-policy">
          <b>Políticas de Privacidade</b>
        </h5>

        <label>Texto da página de políticas de privacidade</label>
        <TextEditor id="privacy-policy" v-model="privacyPolicy" type="text" />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import TextEditor from '@/components/ui/TextEditor'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { TextEditor },
  methods: { ...mapActions('settings', ['input']) },
  computed: {
    ...mapGetters('settings', ['form']),
    privacyPolicy: {
      get() {
        return this.form.privacy_policy
      },
      set(privacy_policy) {
        this.input({ privacy_policy })
      }
    }
  }
}
</script>
