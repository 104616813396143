<template>
  <CRow class="mb-4 mt-4">
    <CCol col="12">
      <div class="form-group">
        <h5 for="use-terms">
          <b>Termos de Uso</b>
        </h5>

        <label>Texto da página de termos de uso</label>
        <TextEditor id="use-terms" v-model="termsOfUse" type="text" />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import TextEditor from '@/components/ui/TextEditor'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: { TextEditor },
  methods: { ...mapActions('settings', ['input']) },
  computed: {
    ...mapGetters('settings', ['form']),
    termsOfUse: {
      get() {
        return this.form.terms_of_use
      },
      set(terms_of_use) {
        this.input({ terms_of_use })
      }
    }
  }
}
</script>
