var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"mt-4 mb-4"},[_c('CCol',{attrs:{"col":"12"}},[_c('h5',{staticClass:"mb-4"},[_c('b',[_vm._v("Links")])])]),_vm._l((_vm.links),function(item){return _c('CCol',{key:item.field,attrs:{"col":"12","lg":"6"}},[_c('CInput',{attrs:{"value":_vm.form[item.field],"placeholder":item.placeholder,"description":item.description,"type":"text"},on:{"input":function (val) {
	var _obj;

	return _vm.input(( _obj = {}, _obj[item.field] = val, _obj ));
}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('CButton',{class:item.btnClass},[_c('CIcon',{attrs:{"name":item.icon,"height":"14"}})],1)]},proxy:true}],null,true)})],1)}),_c('CCol',{attrs:{"col":"12"}},[_c('h5',{staticClass:"mb-4"},[_c('b',[_vm._v("Redes sociais")])])]),_vm._l((_vm.networks),function(item){return _c('CCol',{key:item.field,attrs:{"col":"12","lg":"6"}},[_c('CInput',{attrs:{"value":_vm.form[item.field],"placeholder":item.placeholder,"description":item.description,"type":"text"},on:{"input":function (val) {
	var _obj;

	return _vm.input(( _obj = {}, _obj[item.field] = val, _obj ));
}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('CButton',{class:item.btnClass},[_c('CIcon',{attrs:{"name":item.icon,"height":"14"}})],1)]},proxy:true}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }