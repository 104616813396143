<template>
  <CTabs
    :vertical="true"
    variant="pills"
    add-nav-wrapper-classes="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 pr-md-3 mt-3"
    add-tabs-wrapper-classes="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10 mt-3"
  >
    <CTab class="mb-3" v-for="(tab, index) in tabs" :key="index">
      <template slot="title">
        <CIcon :name="tab.icon" />
        <span>{{ tab.name }}</span>
      </template>
      <component :is="tab.component"></component>
    </CTab>
  </CTabs>
</template>

<script>
export default {
  props: { tabs: { type: Array, required: true } }
}
</script>
