<template>
  <FormCardItem title="Configurações">
    <template #title="props">
      <CCardHeader class="d-flex justify-content-between align-items-center">
        <h4 class="mb-0">{{ props.title }}</h4>
      </CCardHeader>
    </template>
    <template>
      <CCol col="12">
        <VerticalTabs :tabs="tabs" />
      </CCol>
    </template>
  </FormCardItem>
</template>

<script>
import FormCardItem from '@/components/ui/Form/FormCardItem'
import VerticalTabs from './VerticalTabs.vue'
import Cms from './Cms.vue'
import Cookies from './Cookies'
import PolicesAndTerms from './PolicesAndTerms'
import WebSite from './WebSite'

const tabs = [
  { name: 'Website', icon: 'cil-window-maximize', component: WebSite },
  { name: 'Cookies', icon: 'cil-av-timer', component: Cookies },
  {
    name: 'Políticas e Termos',
    icon: 'cil-lock-locked',
    component: PolicesAndTerms
  },
  { name: 'CMS', icon: 'cil-image', component: Cms }
]

export default {
  metaInfo: {
    title: 'Settings',
    titleTemplate: ' %s - Manager - Rodonaves Iveco'
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$store.state.auth.permissions.includes('manage_settings')) {
        vm.$router.push('/404')
      }
    })
  },
  components: { FormCardItem, VerticalTabs },
  data: () => ({ tabs })
}
</script>

<style lang="scss">
.settings-horizontal-tabs {
  .nav {
    .envelope-icon {
      margin-top: -5px;
    }

    &.nav-tabs {
      .nav-link {
        color: $gray-700;

        &.active {
          background: none;
          border-color: $gray-200;
          color: $primary;
        }
      }
    }
  }
}
</style>
