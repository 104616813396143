<template>
  <div>
    <slot></slot>

    <slot name="actions">
      <CRow>
        <CCol
          lg="12"
          class="d-flex justify-content-end align-items-center mt-2"
        >
          <a href="javascript:void(0)" class="mr-3" @click="reset">
            Limpar Alterações
          </a>
          <CButton color="success" :disabled="processing" @click="send">
            {{ processing ? 'Salvando...' : 'Salvar' }}
          </CButton>
        </CCol>
      </CRow>
    </slot>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('settings', ['processing', 'form', 'settings'])
  },
  methods: {
    ...mapActions('settings', ['reset', 'store']),
    async send() {
      const saved = await this.store()

      if (saved) {
        this.$notify({
          group: 'notifications',
          type: 'success',
          text: 'Configurações salvas com sucesso'
        })
      } else {
        this.$notify({
          group: 'notifications',
          type: 'error',
          text: 'Erro ao salvar as configurações, tente novamente mais tarde.'
        })
      }
    }
  }
}
</script>
