<template>
  <CRow class="mt-4 mb-4">
    <CCol col="12">
      <h5 class="mb-4">
        <b>Ambiente</b>
      </h5>
    </CCol>
    <CCol col="12" class="d-flex align-items-center">
      <div class="d-flex align-items-center mr-4">
        <label for="homolog" class="mb-0 mr-1">Homologação</label>
        <CSwitch
          id="homolog"
          name="environment"
          value="homolog"
          type="radio"
          class="mx-1"
          color="success"
          variant="3d"
          v-bind="labelIcon"
          :checked="form.environment === 'homolog'"
          @update:checked="setEnvironment"
        />
      </div>
      <div class="d-flex align-items-center mr-4">
        <label for="production" class="mb-0 mr-1">Produção</label>
        <CSwitch
          id="production"
          name="environment"
          value="production"
          type="radio"
          class="mx-1"
          color="success"
          variant="3d"
          v-bind="labelIcon"
          :checked="form.environment === 'production'"
          @update:checked="setEnvironment"
        />
      </div>
    </CCol>
    <CCol col="12">
      <h5 class="mb-4 mt-4">
        <b>Status</b>
      </h5>
    </CCol>
    <CCol col="12" class="d-flex align-items-center">
      <div class="d-flex align-items-center">
        <label for="maintenance" class="mb-0 mr-1">Manutenção</label>
        <CSwitch
          id="maintenance"
          class="mx-1"
          color="success"
          variant="3d"
          v-bind="labelIcon"
          :checked="['1', true].includes(form.environment_under_maintenance)"
          @update:checked="setEnvironmentStatus($event)"
        />
      </div>
    </CCol>
  </CRow>
</template>

<script>
import labelIconMixin from '@/mixin/label-icon'
import { mapGetters, mapActions } from 'vuex'

export default {
  mixins: [labelIconMixin],
  computed: { ...mapGetters('settings', ['form']) },
  methods: {
    ...mapActions('settings', ['input']),
    setEnvironment(checked, event) {
      this.input({ environment: event.target.value })
    },

    setEnvironmentStatus(event) {
      this.input({ environment_under_maintenance: event })
    }
  }
}
</script>
