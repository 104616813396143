<template>
  <BaseForm>
    <CTabs
      add-nav-wrapper-classes="settings-horizontal-tabs"
      :vertical="false"
      variant="tabs"
    >
      <CTab v-for="(tab, index) in tabs" :key="index">
        <template #title> {{ tab.name }} </template>
        <component :is="tab.component"></component>
      </CTab>
    </CTabs>
  </BaseForm>
</template>

<script>
import BaseForm from '../BaseForm.vue'
import UseTerms from './UseTerms.vue'
import AcceptanceTerms from './AcceptanceTerms.vue'
import PrivacyPolices from './PrivacyPolices.vue'

const tabs = [
  { name: 'Políticas de Privacidade', component: PrivacyPolices },
  { name: 'Termos de Uso', component: UseTerms },
  { name: 'Termos de Aceite', component: AcceptanceTerms }
]

export default {
  components: { BaseForm },
  data: () => ({ tabs })
}
</script>
