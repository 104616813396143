<template>
  <CRow
    v-if="form.cookies_notification !== '0' && form.cookies_notification"
    class="my-4 justify-content-between"
  >
    <CCol col="12">
      <div class="form-group">
        <label for="cookies-notification">
          Mensagem da utilização dos cookies no navegador
        </label>

        <TextEditor
          id="cookies-notification"
          v-model="cookiesNotificationText"
          type="simpletext"
        />

        <small class="span_description">
          Texto que será exibido quando o usuário acessar o site pela primeira
          vez. Caso este campo esteja vazio será exibido um texto padrão no
          site.
        </small>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TextEditor from '@/components/ui/TextEditor'

export default {
  methods: { ...mapActions('settings', ['input']) },
  computed: {
    ...mapGetters('settings', ['form']),
    cookiesNotificationText: {
      get() {
        return this.form.cookies_notification_text
      },
      set(cookies_notification_text) {
        this.input({ cookies_notification_text })
      }
    }
  },
  components: { TextEditor }
}
</script>
