<template>
  <BaseForm>
    <CRow class="mb-4">
      <CCol col="12" class="mb-1">
        <h5>
          <b>CMS</b>
        </h5>
      </CCol>

      <CCol col="12" v-for="(config, index) in mediaConfig" :key="index">
        <FileUploader
          :id="`cmsMedia${index}`"
          :enable-crop-on-uploader="false"
          :max-files="1"
          :uploader-info="config.uploaderInfo"
          :extensions="config.extensions"
          :accept-types="config.acceptTypes"
          :height="config.height"
          :width="config.width"
          :medias="form[config.name]"
        />
      </CCol>
    </CRow>
  </BaseForm>
</template>

<script>
import FileUploader from '@/components/ui/Fileuploader'
import BaseForm from './BaseForm.vue'
import { mapGetters } from 'vuex'

const mediaConfig = [
  {
    name: 'background',
    uploaderInfo: {
      label: 'Background da tela de login',
      description: '(Formato JPG/PNG)'
    },
    extensions: 'jpg,jpeg,png',
    acceptTypes: 'image/png,image/jpeg',
    width: 1920,
    height: 1080
  },
  {
    name: 'coloredLogo',
    uploaderInfo: {
      label: 'Logo colorida',
      description: '(Formato SVG)'
    },
    extensions: 'svg',
    acceptTypes: 'image/svg+xml',
    maxHeight: 55,
    maxWidth: 250
  },
  {
    name: 'whiteLogo',
    uploaderInfo: {
      label: 'Logo branca ',
      description: '(Formato SVG)'
    },
    extensions: 'svg',
    acceptTypes: 'image/svg+xml',
    maxHeight: 55,
    maxWidth: 250
  },
  {
    name: 'whiteLogoReduced',
    uploaderInfo: {
      label: 'Logo branca reduzida ',
      description: '(Formato SVG)'
    },
    extensions: 'svg',
    acceptTypes: 'image/svg+xml',
    maxHeight: 55,
    maxWidth: 250
  }
]

export default {
  components: { BaseForm, FileUploader },
  data: () => ({ mediaConfig }),
  computed: {
    ...mapGetters('settings', ['mediasForm']),
    form: {
      get() {
        return { ...this.mediasForm }
      },
      set(val) {
        this.$store.commit('settings, SET_MEDIAS_FORM', val)
      }
    }
  }
}
</script>
