<template>
  <BaseForm>
    <CTabs
      add-nav-wrapper-classes="settings-horizontal-tabs"
      :vertical="false"
      variant="tabs"
    >
      <CTab v-for="(tab, index) in tabs" :key="index">
        <template #title> {{ tab.name }} </template>
        <component :is="tab.component"></component>
      </CTab>
    </CTabs>
  </BaseForm>
</template>

<script>
import BaseForm from '../BaseForm.vue'
import Cookies from './Cookies.vue'
import Message from './Message.vue'

const tabs = [
  { name: 'Cookies', component: Cookies },
  { name: 'Mensagem da Notificação', component: Message }
]

export default {
  components: { BaseForm },
  data: () => ({ tabs }),
  methods: {
    selectPosition(position) {
      this.form.cookies_notification_position = position
    }
  }
}
</script>
