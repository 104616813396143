<template>
  <div>
    <CRow class="mt-4">
      <CCol col="12">
        <h5>
          <b>Emails</b>
        </h5>
      </CCol>
    </CRow>
    <CRow class="mt-4 mb-4">
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_models"
          @input="(e) => input({ emails_models: e })"
          label="Modelos"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_vehicles"
          @input="(e) => input({ emails_vehicles: e })"
          label="Veículos"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_parts"
          @input="(e) => input({ emails_parts: e })"
          label="Peças"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_insurances"
          @input="(e) => input({ emails_insurances: e })"
          label="Seguros"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_financing"
          @input="(e) => input({ emails_financing: e })"
          label="Iveco Capital"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_consortium"
          @input="(e) => input({ emails_consortium: e })"
          label="Consórcio"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_review_schedule"
          @input="(e) => input({ emails_review_schedule: e })"
          label="Agendamento"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_maintenance_plan"
          @input="(e) => input({ emails_maintenance_plan: e })"
          label="Planos de manutencao"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_top_driver"
          @input="(e) => input({ emails_top_driver: e })"
          label="Top Driver"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <CCol col="12" sm="6">
        <CInput
          :value="form.emails_iveco_connect"
          @input="(e) => input({ emails_iveco_connect: e })"
          label="Iveco Connect"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol>
      <!-- <CCol col="12" sm="6">
        <CInput
          :value="form.emails_work_with_us"
          @input="(e) => input({ emails_work_with_us: e })"
          label="Trabalhe conosco"
          placeholder="Ex: contato@rodonavesiveco.com.br;joao@rodonavesiveco.com.br"
          description="Insira os emails que vão receber o contato desse setor, separado por ponto-e-vírgula."
        />
      </CCol> -->
    </CRow>
  </div>
</template>

<script>
import labelIconMixin from '@/mixin/label-icon'
import { mapGetters, mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions('settings', ['input'])
  },
  computed: { ...mapGetters('settings', ['form']) },
  mixins: [labelIconMixin]
}
</script>
